*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  overflow: hidden;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  font-weight: 300;
  line-height: 1.6;
}

.container {
  width: 60%;
  margin: 2rem auto;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 3.5vw);
  grid-gap: 1.5rem;
  width: 80%;
  margin: 5px auto;
  transition: all 0.8s ease;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  opacity: 1;
}

figure {
  background: #343434;
  position: relative;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 6;
}

.gallery__item--2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 6;
  grid-row-end: 9;
}

.gallery__item--3 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 9;
}

.gallery__item--4 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}

.gallery__item--5 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 9;
}

.gallery__item--6 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 9;
}

.App {
  height: 100vh;
  margin-top: 4vh;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 120px;
  position: absolute;
  top: 2%;
  left: 1%;
}

footer {
  color: #fff;
  font-size: 10px;
  padding: 20px;
  position: absolute;
  bottom: 0%;
  left: 0;
  /* font-weight: 600; */
  line-height: 12px;

}
footer > p {
  /* margin: 2px; */
}
.demo-bg {
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  max-height: 100vh;
  object-fit: none;
}
figcaption {
  color: #343434;
  font-size: 1.1rem;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10%);
  background-color: #000000c7;
  color: #fff;
  z-index: 2;
  padding: 5px;
  /* border-top-right-radius: 5px; */
  border-bottom-right-radius: 5px;
  border: 1px solid #343434;
  text-align: center;
  width: 90%;
  border-radius: 15px;

}

.file-area {
  width: 100%;
  position: relative;
}
.file-area input[type='file'] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.file-area .file-dummy {
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  text-align: center;
  transition: background 0.3s ease-in-out;
}
.file-area .file-dummy .success {
  display: none;
}
.file-area:hover .file-dummy {
  background: rgba(255, 255, 255, 0.1);
}
.file-area input[type='file']:focus + .file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}
.file-area input[type='file']:valid + .file-dummy {
  border-color: rgba(0, 255, 0, 0.4);
  background-color: rgba(0, 255, 0, 0.3);
}
.file-area input[type='file']:valid + .file-dummy .success {
  display: inline-block;
}
.file-area input[type='file']:valid + .file-dummy .default {
  display: none;
}

.upload_container {
  margin: 0;
  padding: 0;
  font-weight: 300;
  height: 100vh;
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  background: #607d8b;
}

h1 {
  text-align: center;
  margin: 50px auto;
  font-weight: 100;
}

label {
  font-weight: 500;
  display: block;
  margin: 4px 0;
  text-transform: uppercase;
  font-size: 13px;
  overflow: hidden;
}
label span {
  float: right;
  text-transform: none;
  font-weight: 200;
  line-height: 1em;
  font-style: italic;
  opacity: 0.8;
}

.form-controll {
  display: block;
  padding: 8px 16px;
  width: 100%;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: 200;
}
.form-controll:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

button {
  padding: 8px 30px;
  background: rgba(255, 255, 255, 0.8);
  color: #053777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  border: 0;
  text-shadow: 0 1px 2px #fff;
  cursor: pointer;
}

.form-group {
  max-width: 500px;
  margin: auto;
  margin-bottom: 30px;
}

.back-to-article {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 18px;
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
}
.back-to-article:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.8);
}
.progress-wrapper {
  /* border: 3px solid blue;
   */
  /* d4d4d452 */
}

.progress-bar-container {
  /* background-color: pink; */
}

.progress-bar-completed {
  background-color: #b0333391;
  /* width: 80%; */
}

.progress-label {
  /* font-size: 20px; */
  color: #d4d4d491;
}

h2 {
  /* margin-top: 20px; */
  font-size: 53px;
  text-align: center;
  color: #f4f5f8;
  text-shadow: 1px 1px rgb(0 0 0 / 55%);
  position: absolute;
  top: 8%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.react-clock {
  background-color: white;
  border-radius: 50%;
}
.react-clock__face {
  border: 3px solid white;
}
.react-clock__second-hand {
  transition: transform cubic-bezier(.68,0,.27,1.55) 0.2s;
}
.react-clock__second-hand__body:before {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  background-color: red;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.react-clock__second-hand__body:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: red;
  border-radius: 50%;
  transform: translateX(-50%);
}
